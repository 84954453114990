<template>

    <div>
        <v-snackbar
                timeout="2000"
                :value="snackbarShow"
                :color="snackbarColor"
                absolute
                top
                v-model="snackbarShow"
        >{{ snackbarText }}
        </v-snackbar>
        <v-card>
            <v-container fluid>
                <v-data-iterator
                        :items="items.parent"
                        :items-per-page.sync="itemsPerPage"
                        hide-default-footer
                        loading-text=""
                        loading
                >
                    <template v-slot:header>
                        <v-toolbar
                                class="mb-2"
                                color="indigo darken-5"
                                dark
                                flat
                        >
                            <v-toolbar-title>结构安全</v-toolbar-title>
                            <v-divider
                                    class="ma-4"
                                    vertical
                                    dark
                            ></v-divider>
                            <p class="fontSize">承重系统</p>
                        </v-toolbar>
                        <v-row v-show="items.length===0">
                            <v-col cols="4" v-for="i in 3">
                                <v-card>
                                    <v-sheet class="pa-3">
                                        <v-skeleton-loader
                                                class="mx-auto"
                                                max-width="300"
                                                type="article, actions"
                                        ></v-skeleton-loader>
                                    </v-sheet>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:default="props" v-show="items.length!==0">
                        <v-row>
                            <v-col
                                    v-for="(item,i) in props.items"
                                    :key="i"
                                    cols="12"
                                    sm="6"
                                    md="4"
                                    lg="4"
                            >
                                <v-card>
                                    <v-card-title class="subheading font-weight-bold">
                                        {{item.name}}
                                    </v-card-title>

                                    <v-divider></v-divider>

                                    <v-list dense>
                                        <v-list-item v-for="(sub_item,j) in items.children[i]" :key="j">

                                            <div class="listTitle">
                                                {{sub_item.name}}:
                                            </div>
                                            <v-text-field
                                                    class="inputStyle" solo dense v-model="items.values[i][j]">
                                            </v-text-field>
                                            <v-select
                                                    v-model="items.ranks[i][j]"
                                                    :items="grade"
                                                    label="等级"
                                                    dense
                                                    solo
                                                    style="width:120px; margin-left: 5px"
                                            ></v-select>
                                        </v-list-item>
                                    </v-list>
                                    <v-row>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                                color="green"
                                                dark
                                                small
                                                class="mb-2 mr-1"
                                                @click="reset(items,i)"
                                        >重置
                                        </v-btn>
                                        <v-btn
                                                @click="save(items,i)"
                                                color="primary"
                                                dark
                                                small
                                                class="mb-2 mr-5">保存
                                        </v-btn>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>

                </v-data-iterator>
            </v-container>
            <v-container fluid>
                <v-data-iterator
                        :items="items1.parent"
                        :items-per-page.sync="itemsPerPage"
                        hide-default-footer
                        loading-text=""
                        loading
                >
                    <template v-slot:header>
                        <v-toolbar
                                class="mb-2"
                                color="indigo darken-5"
                                dark
                                flat
                        >
                            <v-toolbar-title>使用安全</v-toolbar-title>


                        </v-toolbar>

                        <v-row v-show="items1.length===0">
                            <v-col cols="4" v-for="i in 3">
                                <v-card>
                                    <v-sheet class="pa-3">
                                        <v-skeleton-loader
                                                class="mx-auto"
                                                max-width="300"
                                                type="article, actions"
                                        ></v-skeleton-loader>
                                    </v-sheet>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:default="props" v-show="items1.length!==0">
                        <v-row>
                            <v-col
                                    v-for="(item,i) in props.items"
                                    :key="i"
                                    cols="12"
                                    sm="6"
                                    md="4"
                                    lg="4"
                            >
                                <v-card>
                                    <v-card-title class="subheading font-weight-bold">
                                        {{item.name}}
                                    </v-card-title>

                                    <v-divider></v-divider>

                                    <v-list dense>
                                        <v-list-item v-for="(sub_item,j) in items1.children[i]" :key="j">
                                            <div class="listTitle">
                                                {{sub_item.name}}:
                                            </div>
                                            <v-text-field
                                                    class="inputStyle" solo dense v-model="items1.values[i][j]">
                                            </v-text-field>
                                            <v-select
                                                    v-model="items1.ranks[i][j]"
                                                    :items="grade"
                                                    label="等级"
                                                    dense
                                                    solo
                                                    style="width:120px; margin-left: 5px"
                                            ></v-select>
                                        </v-list-item>
                                    </v-list>
                                    <v-row>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                                color="green"
                                                dark
                                                small
                                                class="mb-2 mr-1"
                                                @click="reset(items1,i)"
                                        >重置
                                        </v-btn>
                                        <v-btn
                                                @click="save(items1,i)"
                                                color="primary"
                                                dark
                                                small
                                                class="mb-2 mr-5">保存
                                        </v-btn>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>

                </v-data-iterator>
            </v-container>
        </v-card>
    </div>
</template>

<script>
    import * as safeInfo from "../../network/Details/safeInfo";

    export default {
        created() {
            // let buildingId = this.$route.query.buildingId
            safeInfo.getSafeInfo({
                buildingId: this.buildingId
            }).then(res => {
                if (res.message !== "查询成功")
                    return
                console.log(res)
                this.items = res.data.item1;
                this.items1 = res.data.item2;
                this.getDetails(this.items)
                this.getDetails(this.items1)
            }).catch(err => {
                console.log(err)
            })
        },
        data() {
            return {
                add: false,
                snackbarShow: false,
                snackbarText: '部分空值选项保存失败',
                snackbarColor: '#ff4081',
                buildingId: this.$route.query.buildingId,
                itemsPerPage: 4,
                items: [],
                items1: [],
                // items: [
                //     {
                //         name: '地基基础',
                //         listName: ['地基变形', '地基承载力', '边坡场地稳定性'],
                //         values: ['', '', '']
                //     },
                //     {
                //         name: '上部承重结构',
                //         listName: ['上部结构承载力', '结构整体性', '结构侧向位移'],
                //         values: ['', '', '']
                //     },
                //     {
                //         name: '围护系统承重部分',
                //         listName: ['承重能力', '结构整体性'],
                //         values: ['', '']
                //     }
                // ],
                // items1: [
                //     {
                //         name: '围护结构非承重部分',
                //         listName: ['地基变形', '外墙幕墙', '外墙面砖', '窗户', '吊顶', '屋面瓦'],
                //         values: ['', '', '', '', '', '']
                //     },
                //
                //     {
                //         name: '设施设备',
                //         listName: ['供气系统', '消防系统', '供电系统', '排水系统', '电梯系统', '防雷设施'],
                //         values: ['', '', '', '', '', '']
                //     },
                //     {
                //         name: '建筑附属物',
                //         listName: ['灯箱', '广告牌'],
                //         values: ['', '']
                //     }
                // ],
                grade: ['A', 'B', 'C', 'D']
            }
        },
        computed: {},
        methods: {
            getDetails(obj) {
                for (let i = 0; i < obj.parent.length; i++) {
                    let child = obj.parent[i];
                    safeInfo.getItemValue({
                        buildingId: this.buildingId,
                        safetyId: child.safetyId
                    }).then(res => {
                        if (res.message === "查询成功") {
                            this.$set(obj.values, i, res.data.values)
                            for (let j = 0; j < res.data.values.length; j++) {
                                obj.children[i][j]['empty'] = res.data.values[j] === '';
                            }
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                    safeInfo.getItemRank({
                        buildingId: this.buildingId,
                        safetyId: child.safetyId
                    }).then(res => {
                        if (res.message === "查询成功") {
                            this.$set(obj.ranks, i, res.data.ranks)
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                }
            },
            reset(obj, i) {
                for (let k = 0; k < obj.values[i].length; k++) {
                    this.$set(obj.values[i], k, '');
                    this.$set(obj.ranks[i], k, '');
                    v.push("")
                }
            },
            save(obj, i) {
                let list = obj.children[i];
                for (let j = 0; j < list.length; j++) {
                    console.log(list[j])
                    let value = obj.values[i][j]
                    let rank = obj.ranks[i][j]
                    if (value === '' || rank === '') {
                        console.log("不能为空")
                        return
                    }
                    let indexId = list[j].safetyId
                    let parentId = list[j].parentId
                    let flag = list[j]['empty'];
                    safeInfo.updateItem({
                        buildingId: this.buildingId,
                        indexId: indexId,
                        parentId: parentId,
                        value: value,
                        rank: rank,
                    }, flag).then(res => {
                        if (res.message.indexOf("失败")) {
                            this.snackbarShow = true;
                        }
                        console.log(res)
                    }).catch(err => {
                        console.log(err)
                    })
                }
            }
        },
    }
</script>

<style scoped>
    . inputStyle {
        padding-left: 10px;
    }

    .fontSize {
        font-size: 20px;
        margin-top: 18px;
    }

    .listTitle {
        width: 180px;
        height: auto;
        margin-bottom: 20px;
    }
</style>

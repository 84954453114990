import {request} from "../request";
export function getSafeInfo(data) {
    return request({
        url: 'security/list',
        method: 'get',
        params: data
    })
}
export function getItemValue(data) {
    return request({
        url: 'security/value',
        method: 'get',
        params: data
    })
}
export function getItemRank(data) {
    return request({
        url: 'security/rank',
        method: 'get',
        params: data
    })
}
export function updateItem(data,flag) {
    return request({
        url: 'security/'+(flag?"add":"update"),
        method: 'post',
        params: data
    })
}